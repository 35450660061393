import React from 'react';
import './LoadingOverlay.css';

const LoadingOverlay = () => (
  <div className="loading-overlay">
    <div className="loading-content">
      <div className="water-container">
        <div className="water-splash"></div>
        <img src="/duck-logo.png" alt="Rubber Duck" className="duck-logo" />
      </div>
      <p>You can always click "Get Insights" again for a fresh perspective!</p>
    </div>
  </div>
);

export default LoadingOverlay;