import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import LoadingOverlay from './LoadingOverlay';
import { Analytics } from "@vercel/analytics/react";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const API_URL = process.env.REACT_APP_API_URL || 'https://decisive-fold-436618-h2.uk.r.appspot.com';

function App() {
  const [url, setUrl] = useState('');
  const [jobData, setJobData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  // Add this new state for the theme
  const [theme, setTheme] = useState('light');
  // Add this useEffect hook to initialize the theme
  
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'light';
    setTheme(savedTheme);
    document.documentElement.setAttribute('data-theme', savedTheme);
  }, []);

  // Add this function to toggle the theme
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
    // Track the theme toggle event
    trackEvent('theme_toggled', 'engagement', newTheme); // New line added
  };

  const validateUrl = (url) => {
    if (typeof url !== 'string') return false;
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const isValidJobUrl = (url) => {
    if (typeof url !== 'string') return false;
    const keywords = ['job', 'role', 'detail','candidate','apply','position','career','id'];
    const lowercaseUrl = url.toLowerCase();
    return keywords.some(keyword => lowercaseUrl.includes(keyword));
  };

  const isRestrictedDomain = (url) => {
    if (typeof url !== 'string') return false;
    const restrictedDomains = ['linkedin.com','glassdoor.com','joinhandshake.com','wellfound.com','indeed.com','lhh.com'];
    return restrictedDomains.some(domain => url.includes(domain));
  };

  const trackEvent = (action, category, label) => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', action, {
        'event_category': category,
        'event_label': label
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Track the "Gain Insights" button click
    trackEvent('gain_insights_clicked', 'engagement', url);

    setIsLoading(true);
    setError('');
    setJobData(null);
    setShowFeedback(false);

    // Check if url is defined and not empty
    if (!url || typeof url !== 'string' || url.trim() === '') {
    setError('Please enter a valid URL');
    setIsLoading(false);
    return;
    }

    if (!validateUrl(url)) {
      setError('This does not look like a valid URL?');
      setIsLoading(false);
      return;
    }

    if (!isValidJobUrl(url)) {
      setError('This URL does not appear to be a job posting?');
      setIsLoading(false);
      return;
    }    

    if (isRestrictedDomain(url)) {
      setError('We are not able to process job posts from LinkedIn or any sites that require login. Can you find the original source?');
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/summarize`, { url });
      setJobData(response.data);
      setShowFeedback(true);

      // Track successful job data retrieval
      trackEvent('job_data_retrieved', 'success', url);

    } catch (error) {
      console.error('Error fetching job data:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data.error.includes('404')) {
            setError('This job posting cannot be found or may not be live.');
          } else {
          setError(`Server error: ${error.response.status} - ${error.response.data.message || 'Unknown error'}`);
          }
        } else if (error.request) {
          // The request was made but no response was received
          if (error.code === 'ECONNABORTED') {
            setError('The request timed out. The server took too long to respond.');
          } else {
            setError('No response received from the server. Please check your internet connection and try again.');
          }
        } else {
          // Something happened in setting up the request that triggered an Error
          setError(`An error occurred: ${error.message}`);
        }
      } else {
        setError('An unexpected error occurred. Please try again.');
      }

      // Track error event
      trackEvent('job_data_error', 'error', error.message);
    }
    setIsLoading(false);
  };

  const formatBulletPoints = (parsedBullets) => {
    return Object.entries(parsedBullets)
      .map(([responsibility, bullets]) => 
        `${responsibility}:\n${bullets.map(bullet => `- ${bullet}`).join('\n')}`)
      .join('\n\n');
  };

  const CopyComponent = () => {
    if (!jobData?.parsed_bullets) return null;

    const bulletPointsText = formatBulletPoints(jobData.parsed_bullets);
    
    return (
      <CopyToClipboard 
        text={bulletPointsText}
        onCopy={() => {
          trackEvent('clipboard_clicked', 'engagement', url);
          alert('💡 Bullet point ideas copied to clipboard!');
        }}
      >
        <button className="copy-send-button">Copy<b>💡Bullet Point Ideas</b></button>
      </CopyToClipboard>
    );
  };

  const handleSendEmail = async () => {
    if (!email || !jobData?.parsed_bullets) {
      alert('Please enter a valid email address and ensure job data is loaded.');
      return;
    }
    try {
      const bulletPointsText = formatBulletPoints(jobData.parsed_bullets);

      const emailContent = `
        <p>Thank you for checking Career Duck out!</p>
        
        <p>This is the original job posting URL:<br>
        <a href="${url}">${url}</a></p>
        
        <p>----------------------------------------------------------------------------------------------------</p>

        <p><strong>Title:</strong> ${jobData.parsed_summary.title}</p>
        
        <p><strong>Location:</strong> ${jobData.parsed_summary.location}</p>
        
        <p><strong>Bullet Points Ideas:</strong></p>
        
        <pre>${bulletPointsText}</pre>

        <p>----------------------------------------------------------------------------------------------------</p>

        <p>Get questions? Quack at us at 📧 <a href="mailto:contact@careerduck.io" className="email-link">contact@careerduck.io</a></p>
      `;

      await axios.post(`${API_URL}/send-email`, { 
        email, 
        subject: `🦆 Your Career Duck Bullet Points for ${jobData.parsed_summary.title}`,
        content: emailContent,
        contentType: 'text/html'  // Add this line
      });

      setIsEmailSent(true);
      trackEvent('email_sent', 'engagement', email);
      alert('💡 Bullet points have been sent to your email!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again.');
    }
    setIsLoading(false);
  };
    
  return (
    <>
    <div className="App">
      {isLoading && <LoadingOverlay />}
      <header className="App-header">
      <div className="logo-container"> {/* New div for logo and text */}
        <img src="/duck-logo.png" alt="Career Duck Logo" className="App-logo" />
        <h1>Career Duck</h1>
      </div>
      <div className="theme-toggle-container">
        <label className="toggle-switch">
          <input type="checkbox" onChange={toggleTheme} checked={theme === 'light'} />
            <span className="slider">
              <span className="icon moon">🌙</span>
              <span className="icon sun">☀️</span>
            </span>
          </label>
      </div>
      </header>
      <main>
        <h1>Get Job Summary and Resume Bullet Points</h1>
        <h1 className="subtitle">The Easy Way</h1>
        <form onSubmit={handleSubmit} className="url-form">
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Paste a job post URL here..."
            required
            className="url-input"
          />
          <button type="submit" disabled={isLoading} className="submit-button">
          {isLoading ? 'Loading...' : 'Get Insights'}
          </button>
        </form>

        {showFeedback && (
          <>
            <div className="feedback-message">
              Psst... Not satisfied with the results? Click "Get Insights" again for a fresh perspective! 🔄
            </div>
            <div className="feedback-message">
              Want to give us a kudos or share feedback? Email us at <a href="mailto:contact@careerduck.io" className="email-link">&lt;contact@careerduck.io&gt;</a> 📧
            </div>
            <div className="feedback-message">
              <span>You can</span>
              <CopyComponent />
              <span> or </span>
              <span className="email-sender">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="email-input"
                />
              </span>
              <button onClick={handleSendEmail} className="copy-send-button">Send to Yourself<b>💡Bullet Point Ideas</b></button>
              <span>
              for later review
              </span>
            </div>
          </>
        )}

        {error && <div className="error-message">Psst... {error} 😵‍💫</div>}
        <div className="results-container">
          <div className="job-summary">
            <h2>📝 Job Summary</h2>
            {jobData ? (
              <div>
                <h3>Title:</h3> 
                <p>{jobData.parsed_summary.title}</p>
                <h3>Company Overview:</h3> 
                <p>{jobData.parsed_summary.company_overview}</p>
                <h3>Location:</h3>
                <p>{jobData.parsed_summary.location}</p>
                <h3>Key Responsibilities:</h3>
                <ul>
                  {jobData.parsed_summary.responsibilities.map((resp, index) => (
                    <li key={index}>{resp}</li>
                  ))}
                </ul>
                <h3>Required Qualifications:</h3>
                <ul>
                  {jobData.parsed_summary.qualifications.map((qual, index) => (
                    <li key={index}>{qual}</li>
                  ))}
                </ul>
                <h3>Benefits:</h3>
                <ul>
                  {jobData.parsed_summary.benefits.map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                  ))}
                </ul>
                <h3>Keywords:</h3>
                <p>
                  {jobData.parsed_summary.keywords.join(', ')}
                </p>
              </div>
            ) : (
              <p className="placeholder-text">Job summary will appear here after you click "Get Insights".</p>
            )}  
          </div>
          <div className="resume-bullets">
            <h2>💡 Bullet Point Ideas</h2>
            {jobData && jobData.parsed_bullets ? (
              <div>
                {Object.entries(jobData.parsed_bullets).map(([responsibility, bullets], index) => (
                  <div key={index}>
                    <h3>{responsibility}</h3>
                    <ul>
                      {bullets.map((bullet, bulletIndex) => (
                        <li key={bulletIndex}>{bullet}</li>
                      ))}
                    </ul>
                  </div>      
                ))}              
              </div>
            ) : (
              <p className="placeholder-text">Bullet points will appear here after you click "Get Insights".</p>
            )}
          </div>
        </div>
      </main>
    </div>
    <Analytics />
    </>
  );
}

export default App;